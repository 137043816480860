html {
  /* user-select: none; */
}

.logo-image {
  max-height: 48pt;
}

.shadow-navbar {
  --tw-shadow: 0 15px 30px -10px rgb(0 0 0 / 0.08);
  --tw-shadow-colored: 0 15px 30px -10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgb(0 0 0 / 0.08)),
    var(--tw-ring-shadow, 0 0 rgb(0 0 0 / 0.08)), var(--tw-shadow);
}

/* Estilos para el Sidepanel */
.sidepanel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  color: #1a202c;
  background-color: white;
  overflow-y: none;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  transform: translateX(100%);
}

.sidepanel-desktop {
  transform: translateX(100%);
}

.sidepanel-open {
  transform: translateX(0);
}

.sidepanel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 998;
}

.sidepanel-overlay-open {
  opacity: 1;
  pointer-events: auto;
}

.container {
  max-width: 1024px !important;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  .container {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 24px;
  }
}

.Input,
.Textarea {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 15px;
  color: #000;
  background-color: var(--accent-2);
}

input {
  all: unset;
  box-sizing: border-box;
}

.Input:hover,
.Textarea:hover {
  box-shadow: 0 0 0 1px #e3e3e3;
}

.Input:focus,
.Textarea:focus {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.Input::selection,
.Textarea::selection {
  background-color: var(--black-a6);
  color: white;
}

.Input {
  margin-top: 4px;
  padding: 4px 16px;
  height: 48px;
  line-height: 1;
}

.force-font {
  font-family: "Fredoka", sans-serif !important;
}

.disabled-forced {
  pointer-events: none;
  user-select: none;
  filter: grayscale(100);
  opacity: 32%;
}

@import "@radix-ui/colors/black-alpha.css";

/* Estilos específicos para evitar conflictos globales */
.custom-switch-root {
  width: 48px;
  height: 24px;
  background-color: #eeeeee;
  border-radius: 9999px;
  position: relative;
  border: none;
}

.custom-switch-root[data-state="checked"] {
  background-color: #1464ff;
}

.custom-switch-thumb {
  border: none;
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(-4px);
  will-change: transform;
}

.custom-switch-thumb[data-state="checked"] {
  box-shadow: 0 2px 2px var(--black-a7);
  transform: translateX(19px);
}

.custom-label {
  color: white;
  font-size: 15px;
  line-height: 1;
  user-select: none;
}
