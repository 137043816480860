@import "./utilities.css";

.radix-themes {
  --default-font-family: "Fredoka", sans-serif !important; /* or any other font family you prefer */
}
  
body,
html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Fredoka", sans-serif !important;
  font-variation-settings: "wdth" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  max-width: 100vw !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
